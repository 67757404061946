<template>


 <section>

      <div class="container" style="">

        <form class="d-inline" method="post" @submit.prevent="continueSale()" style="background-color: transparent;">
  <h2
                    class="text-justify" style="color: rgb(255,255,255);font-family: Quicksand, sans-serif;"><strong>Cuentas</strong></h2>
                    <small class="form-text text-muted text-justify" style="font-family: Quicksand, sans-serif;">A continuación las cuentas en donde puedes realizar tu transferencia.</small>

  


 <p style="color: white; padding-top:10px; padding-bottom:10px;"><strong> NOTA: Recuerda realizar la transferencia por el monto completo de $ <strong>{{newTotal}}</strong> para que tus entradas sean emitidas. Si realizas la transferencia el día del evento, debe ser del mismo banco para que sea acreditado el pago.  <a @click="returnSale()"><u>Click aquí para cambiar el método de pago.</u></a>
</strong></p>

 

  <div class="card border-light rounded" style="border-radius: 30px, border-color: red; background: transparent; margin-bottom: 20px;">
  <div class="card-body">
    <h5 class="card-title" style="color: white">Banco Internacional</h5>
    <h6 class="card-subtitle mb-2 text-muted">Datos de la cuenta</h6>
    <p class="card-text" style="color: white">Titular: Juan Cárdenas / FOMO PLANES</p>
    <p class="card-text" style="color: white">Documento: 1722310701</p>
     <p class="card-text" style="color: white">Ahorros</p>
    <p class="card-text" style="color: white">Cuenta: 8143384</p>
  </div>
</div>



<div class="form-group"><input class="form-control" required type="file" id="avatar"></div>

                
<div class="form-group"><button required  class="btn btn-primary btn-block" type="submit" style="font-family: Quicksand, sans-serif;"><i style="color: #ffffff;" class="fas fa-qrcode"></i> Confirmar Tickets</button></div>

</form>

       


      </div>

  </section>

    
</template>


<script>
//import { apiService } from "../../common/api.services.js";

export default {
  
    name: 'BankAccount1Ec',
    props:{
        productsProp: {
          type: Object
       },
       total:{
         required: false
       },

       subtotal:{
         required: false
       },

       newTotal:{
        required: false
       },

       event:{
         required: false
       }
     },

     

     data(){
     return{
       email: "",
       name: "",
       last_name: "",
       cc:"",
       phone:"",
       events:[],
       next:null,
       ticketsTable:[],
       order:[],
       fee: 0.00,
       final_total: 0
       
     }
     

   },

    created () {

      if (this.event==44){
        this.final_total=this.subtotal
        this.$emit('isSpecialProm')


      }else if(this.event==99 || this.event==48 || this.event==106 || this.event==105 ||  this.event==108){
        this.$emit('isSpecialCal')

      }else{
        this.$emit('isSpecialCal0Ec')
        this.final_total=this.total
      }
  },

  
   methods:{


    returnSale(){


this.$emit('returnMessageFromBankAccount')
},


  continueSale(){

      var imagefile = document.querySelector('#avatar')

      let file = imagefile
      this.$emit('messageFromBankAccount', file)

      
    },



    


    openEvent(_id) {
      this.$router.push({ name: "event", params: { id: _id } });
    },


    
   }
}
</script>

<style>

#imagemPosterSlide{
  padding: 10px;
  
}



  
</style>



<style lang="css">
h1 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.blog-card-blog {
  margin-top: 30px;
}

.blog-card {
  display: inline-block;
  position: relative;
  width: 100%;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: rgb(52, 58, 64);
  background-color: rgb(52, 58, 64);
}


.blog-card .blog-table {
  padding: 15px 30px;
}

.blog-table {
  margin-bottom: 0px;
}

.blog-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}

.blog-text-success {
  color: rgb(198,197,197);
}

.blog-card-blog .blog-card-caption {
  margin-top: 5px;
}

.blog-card-caption {
  font-weight: 700;
  font-family: "Roboto Slab", "Times New Roman", serif;
}

.blog-card-caption, .blog-card-caption a {
  color: #333;
  text-decoration: none;
}

p {
  color: #3C4857;
  margin-top: 0;
  margin-bottom: 1rem;
}

.blog-card .ftr {
  margin-top: 15px;
}

.blog-card .ftr .author {
  color: #888;
}

.blog-card .ftr div {
  display: inline-block;
}

.blog-card .author .avatar {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}

.blog-card .ftr .stats {
  position: relative;
  top: 1px;
  font-size: 14px;
}

.blog-card .ftr .stats {
  float: right;
  line-height: 30px;
}

a {
  text-decoration: none;
}


 .d-inline .input {
    border: 0;
    width: 2.5rem;
    padding: 0 .5rem;
  }

.quantity-toggle .button {
    background: rgb(198,197,197);
    font-size: 1rem;
    cursor: pointer;
    color: white;
  }


.container{
  justify-content:left


}



    
</style>

   